<div class="page-footer">
    <!-- <app-navigation></app-navigation> -->
    <!-- <div class="row" *ngIf="pageData.showFootnote">
        <div class="col-sm-12">
            <div class="footnotes">
                <p>
                    <sup>(1)</sup> Sources: Employee Benefits Research Institute, January 2017. A 65-year-old couple,
                    both with median drug
                    expenses would need {{constants.RETIREMENT_NEED | currency:'USD':'symbol':'1.0-0'}} to have a 90%
                    chance of having
                    enough money to cover health care expenses (excluding long-term care) in retirement. Savings Needed
                    for
                    Medigap Premiums, Medicare Part B Premiums, Medicare Part D Premiums and Out-of-Pocket Drug Expenses
                    for
                    Retirement at age 65 in 2016. A 65-year-old man would need
                    {{constants.RETIREMENT_NEED_SINGLE_M | currency:'USD':'symbol':'1.0-0'}} or a 65-year-old woman
                    would need
                    {{constants.RETIREMENT_NEED_SINGLE_F | currency:'USD':'symbol':'1.0-0'}} to have to have a 90%
                    chance of having
                    enough money to cover health care expenses (excluding long-term care) in retirement. Savings Needed
                    for
                    Medigap Premiums, Medicare Part B Premiums, Medicare Part D Premiums and Out-of-Pocket Drug Expenses
                    for
                    Retirement at age 65 in 2016.
                </p>
            </div>
        </div>
    </div> -->
    <div class="row" *ngIf="pageData.showDisclosures">
        <div class="col-sm-12">
            <div class="footer-disclosures">
                <p>
                    Calculators and related tools are provided by SS&C Retirement Solutions, LLC, a party unaffiliated with your firm's service provider, National Financial Services LLC or Fidelity Brokerage Services LLC (“Fidelity”), or your firm. Neither Fidelity nor your firm has been involved in the preparation or the content supplied by SS&C and does not guarantee or assume any responsibility for its content. The retirement planning, estate planning, and tax information contained herein is general in nature, is provided for informational and educational purposes only, and should not be considered legal or tax advice.
                </p>
                <p>
                    Fidelity is not undertaking to provide impartial investment advice, or to give advice in a fiduciary capacity, in connection with any investment or transaction described herein. Fiduciaries are solely responsible for exercising independent judgment in evaluating any transaction(s) and are assumed to be capable of evaluating investment risks independently, both in general and with regard to particular transactions and investment strategies. Fidelity has a financial interest in any transaction(s) that fiduciaries, and if applicable, their clients, may enter into involving Fidelity’s products or services. Always consult an attorney or tax professional regarding your client’s specific legal or tax situation. By using these tools you acknowledge that it is your obligation to comply with any applicable legal and regulatory requirements when providing the reports to your clients. You should consult with your firm’s legal or compliance personnel regarding the use of these tools and reports.
                </p>
                <p>
                    Fidelity Investments® provides clearing, custody, or other brokerage services through National Financial Services LLC or Fidelity Brokerage Services LLC, Members NYSE, SIPC.
                </p>
                <p>
                    936643.2.0
                </p>

                <!-- <div class="row">
                    <div class="col-md-6">
                        <table class="table table-condensed table-bordered col-sm-4">
                            <tbody>
                                <tr>
                                    <td>Are Not FDIC Insured</td>
                                    <td>Are Not Bank Guaranteed</td>
                                    <td>May Lose Value</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> -->

                <!-- <p>
                    &copy; Copyright {{constants.currentYear}} Fidelity Brokerage Services LLC. All Rights Reserved.
                    <span class=""></span>
                </p> -->
            </div>
        </div>
    </div>
</div>

<ng-template #modalSIPC>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Fidelity</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideSIPC()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center">
            <p class="important font-bold mb-0">Important Notice</p>
            <p class="subtitle">You're continuing to another website</p>
        </div>
        <p>
            You're continuing to another website that Fidelity doesn't own or operate.
            It's owner is solely responsible for the website's content offerings and level of security,
            so please refer to the website's posted privacy policy and terms of use.
        </p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-blue" (click)="hideSIPC()">Cancel</button>
        <button class="btn btn-blue" (click)="goToSIPC()">Continue</button>
    </div>
</ng-template>